<template>
  <div class="px-10 pt-2">
    <ModalDisplay>
      <v-sheet light class="font-weight-bold overflow-y-auto overflow-x-hidden" :max-height="breakpoint ? '600px' : '400px'"
        :min-width="breakpoint ? '800px' : '600px'">
        <v-col align-content="center">
          <p class="ma-0 mb-1">センサグループ{{ ttl }}</p>
        </v-col>
        <v-row v-if="isSensorGroupEdit" class="mx-4 mb-2 d-flex">
          <v-col cols="6" class="pa-0">
            <v-text-field :disabled="showConfirmSave || !authority" hide-details dense solo flat outlined class="mr-4"
            @keyup="checkSensorGroupName(sensorGroupName)" v-model="sensorGroupName" />
            <span v-if="existSameSensorGroupName" class="red--text text-caption">既に同じ名前が登録されています</span>
            <span v-if="isOverLengthSensrGroupName" class="red--text text-caption">30文字以内で入力してください。</span>
          </v-col>
          <v-btn v-if="authority && !showConfirmDeleteSensorGroup"
            class="gradient"
            dark
            :disabled="showConfirmSave"
            @click="() => showConfirmDeleteSensorGroup=!showConfirmDeleteSensorGroup">
            センサグループ削除</v-btn>
          <v-col class="pa-0" v-else-if="authority && showConfirmDeleteSensorGroup">
            <div class="d-flex">
              <v-btn
                class="mr-6"
                color="#8AABD0"
                @click="() => showConfirmDeleteSensorGroup = !showConfirmDeleteSensorGroup"
                >キャンセル</v-btn>
              <v-btn
                class="gradient"
                dark
                @click="onClickConfirmDeleteSensorGroup"
                >削除する</v-btn>
            </div>
            <span class="red--text text-caption font-weight-bold">削除して問題なければ「削除する」を押してください。</span>
          </v-col>
        </v-row>
        <v-row v-else class="mx-4 mb-2">
          <v-col cols="6" class="pa-0">
            <v-text-field :disabled="showConfirmSave" hide-details dense solo flat outlined class="mr-4" placeholder="新規作成するセンサグループ名を入力"
              @keyup="checkIfTheNameAlreadyExistsAndLength(newSensorGroupName)" v-model="newSensorGroupName" />
            <span v-if="existSameSensorGroupName" class="red--text text-caption">既に同じ名前が登録されています</span>
            <span v-if="isOverLengthSensrGroupName" class="red--text text-caption">30文字以内で入力してください。</span>
          </v-col>
        </v-row>
        <v-divider class="my-2" color="grey"></v-divider>
        <v-row class="px-4">
          <v-col align-content="center">
            <v-select class="text-body-1" hide-details dense solo flat outlined :items="gatewayData"
              item-text="displayedName" item-value="id" @change="fetchSensorsGateway" v-model="selectedGatewayId"
              placeholder="ゲートウェイを選択" :disabled="showConfirmSave || !authority"/>
          </v-col>
        </v-row>
        <v-simple-table light fixed-header class="overflow-y-auto" :height="breakpoint ? '300px' : '250px'">
          <template v-slot:default>
            <thead>
              <tr>
                <th>選択</th>
                <th>センサタイプ</th>
                <th>シリアル番号</th>
                <th>ゲートウェイ</th>
                <th>表示名</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(data, index) in sensors" :key="index">
                <td>
                  <v-checkbox class="ma-0" v-model="data.sensorAffiliation" hide-details ss dense solo flat
                    :disabled="showConfirmSave || !authority" @change="isSensorGroup(data, data.sensorAffiliation)"></v-checkbox>
                </td>
                <td>{{ data.sensorTypeName }}</td>
                <td>{{ decimal(data.serialNumber) }}</td>
                <td>{{ data.gatewayName }}</td>
                <td>{{ data.displayedName }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-col class="d-flex justify-space-between mt-2 pb-8">
          <v-col class="pa-0">
            <span class="align-center mr-4 text-subtitle-2">CO2排出係数:</span>
            <input v-model="$v.CO2EmissionFactor.$model" :disabled="showConfirmSave || !authority"/>
            <div class="text-caption red--text ml-14" v-if="$v.CO2EmissionFactor.$error">
              <div v-if="!$v.CO2EmissionFactor.required">必須入力してください。</div>
              <div v-if="!$v.CO2EmissionFactor.decimal">整数・小数値入力してください。</div>
            </div>
          </v-col>
          <v-col class="pa-0 d-flex justify-end">
            <div v-if="showConfirmSave">
              <div class="d-flex justify-end">
                <span class="red--text text-caption font-weight-bold">保存して問題なければ「OK」を押してください。</span>
              </div>
              <div class="mt-2 d-flex justify-end">
                <v-btn
                  class="mr-6"
                  color="#8AABD0"
                  @click="() => showConfirmSave = !showConfirmSave"
                  >キャンセル</v-btn>
                  <v-btn
                  class="gradient"
                  dark
                  @click="onClickConfirmSave"
                  >OK</v-btn>
              </div>
            </div>
            <div v-else>
              <v-btn class="mr-4" color="#8AABD0" @click="onClickCancelSensorGroup">キャンセル</v-btn>
              <v-btn v-if="authority" class="authority"
                :disabled="(canNotSave || noSensor || existSameSensorGroupName || isOverLengthSensrGroupName || showConfirmDeleteSensorGroup ||$v.CO2EmissionFactor.$error)"
                @click="() => showConfirmSave = !showConfirmSave">保存</v-btn>
            </div>
          </v-col>
        </v-col>
      </v-sheet>
    </ModalDisplay>
  </div>
</template>

<script>
import { required, decimal } from 'vuelidate/lib/validators'
import { fetchAllValidGateways } from '@/api/gateway'
import { createSensorGroup, fetchAndNotifyAllSensorGroups, getSensorGroupDetail, putSensorGroup, deleteSensorGroup } from '@/api/sensor'
import ModalDisplay from '@/components/ModalDisplay.vue'
import { sensorGroupEditMode } from '@/const'
import $http from '@/services/httpService'
import loading from '@/services/loadingService'
import { getLocalStorage } from '@/store/localstorage'
import { notifyError } from '@/lib/util/toast'

export default {
  components: {
    ModalDisplay,
  },

  props: {
    ttl: {
      type: String,
      required: false,
      default: ''
    },
    gatewayId: {
      type: Number,
      required: false,
      default: null
    },
    selectedSensorGroup: {
      type: Array,
      required: false
    },
    callbackFuncAfterViewSetting: {
      type: Function,
      required: false,
      default: () => {}
    }
  },


  data() {
    return {
      modal: false,
      selectedGatewayId: this.gatewayId,
      editData: [],
      sensors: [],
      sensorType: '',
      CO2EmissionFactor: 0.000453,
      newSensorGroupName: '',
      errorMessage: '',
      sensorGroupName: '',
      bkSensorGroupName: '',
      existSameSensorGroupName: false,
      isOverLengthSensrGroupName: false,
      showConfirmDeleteSensorGroup: false,
      showConfirmSave: false
    }
  },

  validations: {
    CO2EmissionFactor: {
      required,
      decimal
    },
  },

  computed: {
    isSensorGroupCreate() {
      return sensorGroupEditMode.isCreate(this.ttl)
    },
    isSensorGroupEdit() {
      return sensorGroupEditMode.isEdit(this.ttl)
    },

    sensorGroup() {
      return this.$store.state.data.sensorGroups
    },

    gatewayData() {
      return this.$store.state.data.gateways
    },

    breakpoint() {
      const displayValue = this.$vuetify.breakpoint
      return displayValue.lg || displayValue.xl
    },

    canNotSave() {
      return (
        this.CO2EmissionFactor === '' ||
        (this.newSensorGroupName === '' && this.sensorGroupName === '')
      )
    },

    authority() {
      const user = getLocalStorage('loginUser')
      return user.authority
    },

    noSensor() {
      return this.editData.length === 0
    },

    sensorIdsInSelectedSensorGroup() {
      const sensorGroup = this.sensorGroup.find((e) => e.id === this.selectedSensorGroup[0])
      if (!sensorGroup) {
        return []
      }
      return sensorGroup.sensors.map((e) => e.sensor_id)
    }
  },

  created() {
    this.fetchSensorGroupData()
    fetchAllValidGateways(this.$store)

    if (this.isSensorGroupEdit) {
      const selectedSensorGroupId = this.selectedSensorGroup[0]
      const filteredSensorGroup = this.sensorGroup.filter((sensor) => selectedSensorGroupId === sensor.id)[0]
      this.sensorGroupName = filteredSensorGroup.name
      this.bkSensorGroupName = filteredSensorGroup.name
      getSensorGroupDetail(selectedSensorGroupId)
        .then((response) => {
          this.CO2EmissionFactor = response.data.sensor.CO2EmissionFactor
          this.sensors = response.data.sensor.sensors
          this.sensors.forEach((sensor) => {
            if (sensor.sensorAffiliation) {
              this.editData.push(sensor)
            }
          })
          if (this.gatewayId) {
            this.fetchSensorsGateway()
          }
        })
        .catch( (e) => notifyError('センサグループのデータ取得に失敗しました。', e))
        .finally( () => loading.close())
    } else {
      if (this.gatewayId) {
        this.fetchSensorsGateway()
      }
    }


  },

  methods: {
    onClickConfirmDeleteSensorGroup() {
      const sensorGroupId = this.selectedSensorGroup[0]
      loading.show()
      this.showConfirmDeleteSensorGroup = false
      deleteSensorGroup(sensorGroupId)
        .catch( (e) => notifyError('センサグループの削除に失敗しました。', e))
        .finally(() => {
          try {
            this.fetchSensorGroupData()

          } finally {
            this.setViewSettings({selectedSensorGroup: []})
            this.resetEdit()
            loading.close()
          }
        })
    },

    onClickConfirmSave() {
      const editSensorList = this.getEditSensorList()
      const local = getLocalStorage('loginUser')
      let editSensorGroup = {}
      if (this.isSensorGroupEdit) {
        editSensorGroup = {
          contract_id: local.contract_id,
          sensor_group: {
            sgroup_name: this.sensorGroupName,
            co2_coefficients: [this.CO2EmissionFactor],
            sensor: editSensorList,
          },
        }
      } else {
        editSensorGroup = {
          contract_id: local.contract_id,
          sensor_group: {
            sgroup_name: this.newSensorGroupName,
            co2_coefficient: Number(this.CO2EmissionFactor),
            sensor: editSensorList,
          },
        }
      }
      this.showConfirmSave = false

      const sensorGroupId = this.selectedSensorGroup[0]
      const save = () => {
        return this.isSensorGroupEdit? putSensorGroup(sensorGroupId, editSensorGroup) :createSensorGroup(editSensorGroup)
      }

      loading.show()
      save()
        .catch((e) => notifyError('センサグループの保存に失敗しました。', e))
        .finally(() => {
          this.fetchSensorGroupData()
        })

      this.resetEdit()
    },

    onClickCancelSensorGroup() {
      this.resetEdit()
    },

    setViewSettings(params) {
      this.$store.dispatch('view/saveDataDetailView', params).then(() => this.callbackFuncAfterViewSetting())
    },

    /**
     * 10真数に変換
     */
    decimal(value) {
      return parseInt(value, 16)
    },

    /**
     * センサグループをセットする
     *
     * @return {void}
     */
    async fetchSensorGroupData() {
      loading.show()
      fetchAndNotifyAllSensorGroups(this.$store).finally(() => loading.close())
    },

    /**
     * ゲートウェイの絞り込み結果をセットする
     *
     * @return {void}
     */

    async fetchSensorsGateway() {
      loading.show()
      try {
        if (this.isSensorGroupEdit) {
          const params = {
            sensorGroupId: this.selectedSensorGroup[0],
          }
          const response = await $http.get(
            '/iot-sensor-cloud/1.0/gateways/' + this.selectedGatewayId + '/sensors',
            params
          )
          this.sensors = response.data.sensors
        } else {
          const response = await $http.get(
            '/iot-sensor-cloud/1.0/gateways/' + this.selectedGatewayId + '/sensors/for-create'
          )
          this.sensors = response.data.sensors
        }
        this.sensors.forEach((sensor) => {
          const hasSensor = this.editData.some((editData) => editData.id === sensor.id)
          sensor.sensorAffiliation = hasSensor
        })
      } finally {
        loading.close()
      }
    },

    getEditSensorList() {
      return this.editData.map((sensor) => {
          return {
            sensor_id: sensor.id
          }
      })
    },

    checkSensorGroupName(value) {
      if (this.bkSensorGroupName !== value) {
        this.checkIfTheNameAlreadyExistsAndLength(value)
      }
    },

    /**
     * 既存のセンサグループ名かどうかチェックする
     *
     * @param String name センサグループ名
     * @return {void}
     */
    checkIfTheNameAlreadyExistsAndLength(name) {
      const filteredSensorGroupName = this.sensorGroup.filter(sensor => sensor.name === name)
      this.existSameSensorGroupName = filteredSensorGroupName.length !==0
      this.isOverLengthSensrGroupName = name.length > 30
    },

    /**
     * editDataに新しく設定するグループのセンサを一時保管
     * 一致するデータがあれば削除、なければ追加
     *
     * @param {Object, Boolean} data センサデータ isActive グループ追加するかどうか
     * @return {void}
     */
    isSensorGroup(data, isActive) {
      if (isActive) {
        this.editData.push(data)
      } else {
        this.editData = this.editData.filter((sensor) => sensor.id !== data.id)
      }
    },

    resetEdit() {
      this.$emit('modal', false)
      this.selectedGatewayId = null
      this.existSameSensorGroupName = false
      this.isOverLengthSensrGroupName = false
      this.bkSensorGroupName = ''
      this.editData = []
    },
  },
}
</script>

<style lang="scss" scoped>
.gradient {
  background: linear-gradient(to bottom, #009ce6, #006ae7);
}

.authority {
  background: #0066cc !important;
  color: #fff;
}

input {
  width: 110px;
  margin-right: 60px;
  border: 1px solid;
  padding: 6px 12px;
  border-radius: 6px;
}
</style>
