import { getLocalStorage, setLocalStorage} from '@/store/localstorage'

const getViewSettings = () => getLocalStorage('viewSettings')

const initViewSettingsIfNeeded = () => {
  let viewSettings = getViewSettings()
  if (!viewSettings) {
    setLocalStorage('viewSettings', {
      dashboardView: {},
      dataDetailView: {},
      alarmView: {},
      environmentalImpactCalculationView: {},
      powerAnalysisView: {},
      dataDownloadView: {},
      trendGraphView: {}
    })
    viewSettings = getViewSettings()
  }
  return viewSettings
}


const defaultVale = (viewSettings, key) => viewSettings && viewSettings[key] ? viewSettings[key] : {}
const initialState = () => {
  const viewSettings = initViewSettingsIfNeeded()
  return {
    gatewayId: undefined,
    sensorId: undefined,
    dashboardView: defaultVale(viewSettings, 'dashboardView'),
    dataDetailView: defaultVale(viewSettings, 'dataDetailView'),
    alarmView: defaultVale(viewSettings, 'alarmView'),
    environmentalImpactCalculationView: defaultVale(viewSettings, 'environmentalImpactCalculationView'),
    powerAnalysisView: defaultVale(viewSettings, 'powerAnalysisView'),
    dataDownloadView: defaultVale(viewSettings, 'dataDownloadView'),
    trendGraphView: defaultVale(viewSettings, 'trendGraphView')
  }
}
const viewStore = {
  namespaced: true,
  state: initialState,
  getters: {
    gatewayId: (state) => state.gatewayId,
    sensorId: (state) => state.sensorId,
    dashboardView: (state) => state.dashboardView,
    dataDetailView: (state) => state.dataDetailView,
    alarmView: (state) => state.alarmView,
    environmentalImpactCalculationView: (state) => state.environmentalImpactCalculationView,
    powerAnalysisView: (state) => state.powerAnalysisView,
    dataDownloadView: (state) => state.dataDownloadView,
    trendGraphView: (state) => state.trendGraphView,
  },
  mutations: {
    setGatewayId(state, value) {
      state.gatewayId = value
    },
    setSensorId(state, value) {
      state.sensorId = value
    },
    setDashboardView(state, value) {
      const viewSettings = getViewSettings()
      const dashboardView = viewSettings.dashboardView
      const newDashboardView = {
        ...dashboardView,
        ...value
      }
      state.dashboardView = newDashboardView

      const newViewSettings = {
        ...viewSettings,
        dashboardView: dashboardView
      }
      setLocalStorage('viewSettings', newViewSettings)
    },
    setDataDetailView(state, value) {
      const viewSettings = getViewSettings()
      const dataDetailView = viewSettings.dataDetailView
      const newDataDetailView = {
        ...dataDetailView,
        ...value
      }
      state.dataDetailView = newDataDetailView

      const newViewSettings = {
        ...viewSettings,
        dataDetailView: newDataDetailView
      }
      setLocalStorage('viewSettings', newViewSettings)
    },
    setAlarmView(state, value) {
      const viewSettings = getViewSettings()
      const alarmView = viewSettings.alarmView
      const newAlarmView = {
        ...alarmView,
        ...value
      }
      state.alarmView = newAlarmView

      const newViewSettings = {
        ...viewSettings,
        alarmView: newAlarmView
      }
      setLocalStorage('viewSettings', newViewSettings)
    },
    setEnvironmentalImpactCalculationView(state, value) {
      const viewSettings = getViewSettings()
      const environmentalImpactCalculationView = viewSettings.environmentalImpactCalculationView
      const newEnvironmentalImpactCalculationView = {
        ...environmentalImpactCalculationView,
        ...value
      }
      state.environmentalImpactCalculationView = newEnvironmentalImpactCalculationView

      const newViewSettings = {
        ...viewSettings,
        environmentalImpactCalculationView: newEnvironmentalImpactCalculationView
      }
      setLocalStorage('viewSettings', newViewSettings)
    },
    setPowerAnalysisView(state, value) {
      const viewSettings = getViewSettings()
      const powerAnalysisView = viewSettings.powerAnalysisView
      const newPowerAnalysisView = {
        ...powerAnalysisView,
        ...value
      }
      state.powerAnalysisView = newPowerAnalysisView

      const newViewSettings = {
        ...viewSettings,
        powerAnalysisView: newPowerAnalysisView
      }
      setLocalStorage('viewSettings', newViewSettings)
    },
    setDataDownloadView(state, value) {
      const viewSettings = getViewSettings()
      const dataDownloadView = viewSettings.dataDownloadView
      const newDataDownloadView = {
        ...dataDownloadView,
        ...value
      }
      state.dataDownloadView = newDataDownloadView

      const newViewSettings = {
        ...viewSettings,
        dataDownloadView: newDataDownloadView
      }
      setLocalStorage('viewSettings', newViewSettings)
    },
    setTrendGraphView(state, value) {
      const viewSettings = getViewSettings()
      const trendGraphView = viewSettings.trendGraphView
      const newTrendGraphView = {
        ...trendGraphView,
        ...value
      }
      state.trendGraphView = newTrendGraphView

      const newViewSettings = {
        ...viewSettings,
        trendGraphView: newTrendGraphView
      }
      setLocalStorage('viewSettings', newViewSettings)
    },
    reset(state) {
      const s = initialState()
      Object.keys(s).forEach(key => {
        state[key] = s[key]
      })
    }
  },
  actions: {
    saveGatewayId({ commit }, value) {
      commit('setGatewayId', value)
    },
    saveSensorId({ commit }, value) {
      commit('setSensorId', value)
    },
    saveDashboardView({ commit }, value) {
      commit('setDashboardView', value)
    },
    saveDataDetailView({ commit }, value) {
      commit('setDataDetailView', value)
    },
    saveAlarmView({ commit }, value) {
      commit('setAlarmView', value)
    },
    saveEnvironmentalImpactCalculationView({ commit }, value) {
      commit('setEnvironmentalImpactCalculationView', value)
    },
    savePowerAnalysisView({ commit }, value) {
      commit('setPowerAnalysisView', value)
    },
    saveDataDownloadView({ commit }, value) {
      commit('setDataDownloadView', value)
    },
    saveTrendGraphView({ commit }, value) {
      commit('setTrendGraphView', value)
    },
  }
}

export default viewStore
