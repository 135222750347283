class SensorGroupEditMode {
  constructor(){
    this.CREATE = '新規作成'
    this.EDIT = '編集'
  }

  isCreate(value) {
    return this.CREATE === value
  }

  isEdit(value) {
    return this.EDIT === value
  }
}

class SensorDataKind {
  constructor(){
    this.ELECTRIC_POWER_VALUE = '電力量'
    this.ELECTRIC_POWER_INTEGRATED_VALUE = '積算電力'
    this.ELECTRIC_POWER_REAL = '有効電力'
    this.ELECTRIC_POWER_APPARENT = '皮相電力'
    this.ELECTRIC_CURRENT = '電流'
    this.ELECTRIC_POWER_FACTOR = '力率'
    this.ANGLE = '角度'
    this.BATTERY = '電池電圧'
    this.RSSI = '受信強度'
  }

  isElectricPowerValue(value) {
    return this.ELECTRIC_POWER_VALUE === value
  }

  isElectricPowerReal(value) {
    return this.ELECTRIC_POWER_REAL === value
  }

  isElectricPowerApparent(value) {
    return this.ELECTRIC_POWER_APPARENT === value
  }

  isElectricPowerIntegratedValue(value) {
    return this.ELECTRIC_POWER_INTEGRATED_VALUE === value
  }

  isElectricPowerFactor(value) {
    return this.ELECTRIC_POWER_FACTOR === value
  }

  isElectricCurrent(value) {
    return this.ELECTRIC_CURRENT === value
  }

  isAngle(value) {
    return this.ANGLE === value
  }

  isBattery(value) {
    return this.BATTERY === value
  }

  isRssi(value) {
    return this.RSSI === value
  }
}

class ElectricPowerSensorType {
  constructor(){
    this.THREE = ["04"]
    this.SIGLE = ["05"]
  }

  values(){
    return this.THREE + this.SIGLE
  }
}

class SensorType {
  constructor(){
    this._ELECTRIC_POWER_SENSOR_TYPE = new ElectricPowerSensorType()
    this.ANGLE = ["01"]
    this.ELECTRIC_CURRENT = ["02", "03"]
    this.ELECTRIC_POWER = this._ELECTRIC_POWER_SENSOR_TYPE.values()
  }

  isAngle(value) {
    return this.ANGLE.includes(value)
  }

  isElectricCurrent(value) {
    return this.ELECTRIC_CURRENT.includes(value)
  }

  isElectricPower(value) {
    return this.ELECTRIC_POWER.includes(value)
  }

  isElectricPowerSingle(value) {
    return this._ELECTRIC_POWER_SENSOR_TYPE.SIGLE.includes(value)
  }
}
export const sensorGroupEditMode = new SensorGroupEditMode()
export const sensorType = new SensorType()
export const sensorDataKind = new SensorDataKind()
export const alarm_days_of_week = [
  { text: '月', value: 0 },
  { text: '火', value: 1 },
  { text: '水', value: 2 },
  { text: '木', value: 3 },
  { text: '金', value: 4 },
  { text: '土', value: 5 },
  { text: '日', value: 6 },
  { text: '稼働日', value: 7 },
  { text: '休日', value: 8 },
]
